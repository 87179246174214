import { Row, Col, Card } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { ImgIcon } from "../../common/ImgIcon";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  Line,
} from "./styles";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>{t("Tell us everything")}</Para>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:contacto@ika.mx">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/" left="true">
                {t("Application Security")}
              </Large>
              <Large left="true" to="/">
                {t("Software Principles")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/" onClick={() => handleShow()}>
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Customer Support")}
              </Large>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>Chihuahua, Chih</Para>
              <Para>c. Antílope y Cebra #3700 Int. 26A</Para>
            </Col>
            
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="mexico.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://www.facebook.com/InternetKnowledgeApplied"
                src="facebook.svg"
              />
              <SocialLink
                href="https://www.instagram.com/ikachihuahua/"
                src="instagram.svg"
              />
              <SocialLink
                href="https://www.ika.mx/"
                src="web.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
      <Modal
        visible = {show}
        onCancel = {handleClose}
        title = {t("About")}
        onOk = {handleClose}
        centered
        width = { 1200 }
      >
        <Title>{t("The team is")}:</Title>
        <Line>
          <Card title="Edgar Romero" style = {{ width: 200}}>
            <Para>CEO</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
        <Line>
          <Card title="César Barrera" style = {{ width: 200}}>
            <Para>CEO</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
        <Line>
          <Card title="Alfonso Barroso" style = {{ width: 200}}>
            <Para>Backend developer</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
        <Line>
          <Card title="Raul Aguirre" style = {{ width: 200}}>
            <Para>Backend developer</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
        <Line>
          <Card title="Asael Cerros" style = {{ width: 200}}>
            <Para>Frontend Developer</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
        <Line>
          <Card title="Diego Rodríguez" style = {{ width: 200}}>
            <Para>Frontend developer</Para>
            <ImgIcon src="user.png"></ImgIcon>
          </Card>
        </Line>
      </Modal>
    </>
  );
};

export default withTranslation()(Footer);
